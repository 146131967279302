import Job_single from "./Job_single";
import Report from "./Report";
import Notes from "./Notes";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../models/model";

const JobController = ({ token, report,notes }) => {
  const { id } = useParams();
  const [job, setJob] = useState([]);

  useEffect(() => {
    const GetJob = async () => {
      var data = await fetchJob(id);
      data = await db.setdata("jobs", data, parseInt(id));
      setJob(data);
    };
    GetJob();
    return () => db.close();
  }, [db]);

  const fetchJob = async (id) => {
    var request = `https://starforcecrm.com/api/app/jobs/${id}?token=${token}`;
    let response;
    try {
      response = await fetch(request);
    } catch (e) {
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.json();
      return body;
    } catch (e) {
      console.log("test");
      return null;
    }
  };
  if (report) {
    return (
      <>
        {job.length == 1 ? (
          <Report job={job} token={token} />
        ) : (
          "No Jobs To Show"
        )}
      </>
    );
  } else if (notes) {
    return (
      <>
        {job.length == 1 ? (
          <Notes job={job} token={token} />
        ) : (
          "No Jobs To Show"
        )}
      </>
    );
  } else {
    return (
      <>
        {job.length == 1 ? (
          <Job_single job={job} token={token} />
        ) : (
          "No Jobs To Show"
        )}
      </>
    );
  }
};

export default JobController;
