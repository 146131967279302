import { useState, useEffect } from "react";
import { db } from "../models/model";

const Job_buttons = ({ token, job }) => {
  token = token.token;
  job = job.single;
  const [id, setId] = useState([]);
  const [status, setStatus] = useState([]);
  const fetchJobs = async () => {
    var request = `https://starforcecrm.com/api/app/jobs/status?token=${token}`;
    let response;
    try {
      response = await fetch(request);
    } catch (e) {
      console.log("test1");
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.json();
      return body;
    } catch (e) {
    }
  };

  const sync = async (url, job_id, status_id) => {
    const requestOptions = {
      method: "POST",
      body: "test",
      headers: { lastUpdated: Date().toLocaleString() },
    };
    let response;
    try {
      response = await fetch(url, requestOptions);
    } catch (e) {
      /* var data = {};
        data["last_updated"] = Date().toLocaleString();
        data["url"] = url;
        data["query"] = requestOptions;
        db.setqueue("queue", data); */
      job.status_id = status_id;
      console.log("t");
      console.log(parseInt(status_id));
      db.setdata("job_status", null, parseInt(status_id)).then((job_status) => {
        job_status = job_status[0];
        job.status_id = job_status.id;
        job.status = job_status;
        var d = new Date(),
          dformat =
            [d.getFullYear(), d.getMonth() + 1, d.getDate()].join("-") +
            " " +
            [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
        if (job.clock_log.clock_out == "0000-00-00 00:00:00") {
          job.clock_log.clock_out = dformat;
        }

        if (job_status.id == 3) {
          job.clock_log.clock_out = "0000-00-00 00:00:00";
          job.clock_log.type_id = 9;
        } else if (job_status.id == 4) {
          job.clock_log.clock_out = "0000-00-00 00:00:00";
          job.clock_log.type_id = 20;
        } else if (job_status.id == 9) {
          job.clock_log.clock_out = "0000-00-00 00:00:00";
          job.clock_log.type_id = 22;
        }
        db.setdata("jobs", [job], job_id).then((job) => {
          console.log(job[0].status_id);
          window.location.reload();
        });
      });
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.text();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const handeClick = (e) => {
    e.preventDefault();
    const url = e.target.getAttribute("value");
    const status_id = e.target.getAttribute("status_id");
    const job_id = e.target.getAttribute("job_id");
    sync(url, job_id, status_id);
  };
  const setids = (job, token) => {
    console.log(job);
    let user = JSON.parse(token);
    console.log(user.id);
    console.log(job.tech_id.includes(user.id));
    console.log([6, 7, 8, 10].includes(job.status_id));
    setId([]);
    if (
      job.tech_id.includes(user.id) &&
      ![6, 7, 8, 10].includes(job.status_id)
    ) {
      if (job.clock_log) {
        if (job.clock_log.clock_out == "0000-00-00 00:00:00") {
          if (job.clock_log.type_id == 22) {
            setId((id) => [...id, 3]);
            setId((id) => [...id, 5]);
          } else if (job.clock_log.type_id == 9) {
            setId((id) => [...id, 4]);
            setId((id) => [...id, 5]);
          } else if (job.clock_log.type_id == 20) {
            setId((id) => [...id, 5]);
            setId((id) => [...id, 6]);
            setId((id) => [...id, 10]);
          } else {
            console.log("tety");
          }
        } else {
          if (job.clock_log.type_id == 22) {
            setId((id) => [...id, 3]);
            setId((id) => [...id, 9]);
          } else if (job.clock_log.type_id == 9) {
            setId((id) => [...id, 3]);
          } else {
            setId((id) => [...id, 6]);
            setId((id) => [...id, 10]);
          }
          setId((id) => [...id, 4]);
        }
      } else {
        setId((id) => [...id, 3]);
        setId((id) => [...id, 9]);
      }
    } else {
      console.log("no data");
    }
    console.log(id);
  };
  useEffect(() => {
    const GetJobs = async () => {
      console.log("job_vuttons");
      var data = await fetchJobs();
      data = await db.setdata("job_status", data);
      setStatus(data);
    };
    GetJobs();
    setids(job, token);
    return () => db.close();
  }, [db]);
  if (id.length != 0) {
    let return_data = status.map(function (single, i) {
      if (id.includes(parseInt(single.id))) {
        let style = {
          background: single.color,
          color: single.id > 3 ? "white" : "",
          fontSize: "15px",
        };
        let url = `https://starforcecrm.com/clock/clock_from_job/${job.id}/${single.id}?refresh=true&token=${token}`;

        return (
          <a
            key={single.id}
            status_id={single.id}
            job_id={job.id}
            onClick={handeClick}
            value={url}
            className="button_edit btn btn-default clockjob"
            extra=".forjobclock"
            title={single.name}
            style={style}
          >
            <i className={single.icon}></i> {single.name}
          </a>
        );
      } else {
        return "";
      }
    });
    return (
      <>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            background: "#eee",
            padding: "5px",
            zIndex: 22,
            width: "100%",
            height: "45px",
          }}
        >
          <div className="forjobclock"></div>
          {return_data}
        </div>
      </>
    );
  }
};

export default Job_buttons;
