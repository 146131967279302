import Dexie from "dexie";

export class MySubClassedDexie extends Dexie {
  constructor() {
    super("CRMDatabase");
    this.version(1).stores({
      jobs: "id,last_updated",
      queue: "++id,url,last_updated",
      user: "id",
      job_status: "id",
    });
  }

  async setdata(table, data, id = null) {
    try {
      await db.open(); // Reopen needed if database was closed.
    } catch (e) {
      console.log("database has been closed");
    }
    if (!data) {
      if (id) return await db.table(table).where({ id: id }).toArray();
      else return await db.table(table).toArray();
    }
    if (!data) return await db.table(table).toArray();

    if (db)
      db.transaction("rw", db.table(table), async () => {
        await Promise.all(
          data.map(async (row) => {
            row.id = parseInt(row.id);
            var dbrow = await db.table(table).where({ id: row.id }).toArray();
            if (dbrow.length === 0) {
              //add
              await db.table(table).add(row);
            } else {
              //update record
              await db.table(table).put(row, row.id);
            }
          })
        );
      }).catch((e) => {
        // log any errors
        console.log(e.stack || e);
      });
    return data;
  }
  async setqueue(table, data, id = null) {
    try {
      await db.open(); // Reopen needed if database was closed.
    } catch (e) {
      console.log("database has been closed");
    }
    if (!data) {
      //get latest
      if (id) data = db.table(table).orderBy("last_updated").first();
      else data = db.table(table).first();
      return data;
    }
    if (!data) data = await db.table(table).first();

    if (db)
      db.transaction("rw", db.table(table), async () => {
        var dbrow = await db.table(table).where({ url: data.url }).toArray();
        if (dbrow.length === 0) {
          console.log("add");
          console.log(data);
          //add
          await db.table(table).add(data);
        } else {
          console.log("update");
          console.log(data);
          //update record
          await db.table(table).put(data, data.url);
        }
      }).catch((e) => {
        // log any errors
        console.log(e.stack || e);
      });
    return data;
  }
  async setrow(table, data, id = null) {
    try {
      await db.open(); // Reopen needed if database was closed.
    } catch (e) {
      console.log("database has been closed");
    }
    if (!data) {
      //get latest
      if (id) data = db.table(table).orderBy("last_updated").first();
      else data = db.table(table).orderBy("id").first();
      return data;
    }
    if (!data) data = await db.table(table).first();

    if (db)
      db.transaction("rw", db.table(table), async () => {
        data.id = parseInt(data.id);
        var dbrow = await db.table(table).where({ id: data.id }).toArray();
        if (dbrow.length === 0) {
          //add
          await db.table(table).add(data);
        } else {
          //update record
          await db.table(table).put(data, data.id);
        }
      }).catch((e) => {
        // log any errors
        console.log(e.stack || e);
      });
    return data;
  }
  destroy() {
    localStorage.removeItem("login");
    Dexie.delete("CRMDatabase");
    window.location.reload(false);
  }
  async delete_queue(row) {
    await db.table("queue").delete(row.id);
  }
}

export const db = new MySubClassedDexie();
