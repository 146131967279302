import React from "react";
import { db } from "../models/model";
class ReportForm extends React.Component {
  constructor(props) {
    super(props);
    let job = this.props.job;
    this.state = job.job.report;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async sync(url, requestOptions) {
    let response;
    //console.log("test111");
    // alert("test111");
    try {
      response = await fetch(url, requestOptions);
    } catch (e) {
      var postdata = JSON.parse(requestOptions.body);
      var report = this.props.job.job.report;
      Object.keys(postdata).map(function (key) {
        report[key] = postdata[key];
      });
      this.props.job.job.report = report;
      db.setdata("jobs", [this.props.job], this.props.job.id);
      alert("Updated in Offline database");
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.text();
      this.setState({ ...this.state, ["error"]: body });
      alert("Successfully updated!");
      return body;
    } catch (e) {
      alert("Can't update, error!");
      console.log(e);
    }
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  handleChange(e) {
    if (e.target.files != undefined) {
      var img = "";
      var files = e.target.files;
      const filePromises = Object.entries(files).map((item) => {
        return new Promise((resolve, reject) => {
          const [index, file] = item;
          const reader = new FileReader();
          reader.readAsBinaryString(file);

          reader.onload = function (event) {
            // handle reader success
            img = btoa(event.target.result);
            resolve();
          };

          reader.onerror = function () {
            console.log("couldn't read the file");
            reject();
          };
        });
      });
      Promise.all(filePromises)
        .then(() => {
          this.setState({ ...this.state, [e.target.name]: img });
        })
        .catch((error) => {
          console.log(error);
          console.log("something wrong happened");
        });
    } else {
      console.log(e.target);
      /* if (typeof e.target.checked != "undefined") {
         // this.state[e.target.name] = 0;
         this.setState({
           ...this.state,
           [e.target.name]: !this.state[e.target.name],
         });
       } else { */
      console.log("ttt");
      this.setState({ ...this.state, [e.target.name]: e.target.value });
      // }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    //  console.log(this.state);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: { lastUpdated: Date().toLocaleString() },
    };
    this.sync(
      `https://starforcecrm.com/api/app/jobs/${this.props.job.job_id}?token=${this.props.token}`,
      requestOptions
    );
    //console.log("testtt");
    // alert("testttt");
    /*
    const state = {
      method: "post",
      url: `https://starforcecrm.com/app/jobs/`,
      data: this.state,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };
    axios.request(state).then((res) => {
      // const persons = res.data;
      // this.setState({ persons });
    }); */
    // alert("A name was submitted: ");
  }
  Inventory() {
    var job = this.props.job;
    if (job.inventory.length != 0) {
      let return_data = job.inventory.map(function (single, i) {
        if (typeof single.qty != "undefined") {
          let id_name = `consumable[${single.id}]`;
          if (typeof this.state[`${id_name}`] == "undefined") {
            this.setState({ ...this.state, [`${id_name}`]: single.qty });
          }
          //  this.state[`${id_name}`] = single.qty;
          return (
            <tr>
              <td style={{ verticalAlign: "bottom" }}>
                <span>{single.name}</span>
              </td>
              <td>
                <input
                  type="text"
                  style={{ maxWidth: "50px" }}
                  placeholder={single.name}
                  name={id_name}
                  onChange={this.handleChange}
                  class="form-control"
                  value={this.state[`${id_name}`]}
                />
              </td>
            </tr>
          );
        } else {
          var id_name = `items[${single.id}]`;
          if (typeof this.state[`${id_name}`] == "undefined")
            this.setState({ ...this.state, [`${id_name}`]: 1 });
          return (
            <tr>
              <td style={{ verticalAlign: "bottom" }}>
                <span>
                  {single.name} - {single.serial} {single.receiver}
                  {single.card}
                </span>
              </td>
              <td>
                <input
                  type="checkbox"
                  name={id_name}
                  value="1"
                  key={id_name}
                  onChange={this.handleChange}
                  checked={this.state[`${id_name}`]}
                />
              </td>
            </tr>
          );
        }
      }, this);
      if (typeof this.state.items_verified == "undefined")
        this.setState({ ...this.state, ['items_verified']: 0 });
      //  this.state.items_verified = 0;
      return (
        <>
          <input
            type="hidden"
            name="items_verified"
            value="0"
            key="items_verified1"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group" style={{ marginBottom: 0 }}>
                <table className="table" style={{ maxWidth: "300px" }}>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Used</th>
                    </tr>
                  </thead>
                  <tbody>
                    {return_data}
                    <tr>
                      <td style={{ verticalAlign: "bottom" }}>
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          Inventory Items were verified{" "}
                        </span>
                        <br />
                        (if you used any items which are not listed above please
                        add them or contact office to add them with serial
                        numbers)
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="items_verified"
                          value="1"
                          key="items_verified"
                          onChange={this.handleChange}
                          checked={this.state.items_verified}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      if (typeof this.state.items_verified == "undefined")
        this.setState({ ...this.state, ['items_verified']: 1 });
      //  this.state.items_verified = 1;
      return <input type="hidden" name="items_verified" value="1" />;
    }
  }
  render() {
    var input = "";
    let job = this.props.job;
    let fieldlist =
      job.report_form &&
      job.report_form.map((item, i) => {
        if (item.selectdata.type) {
          // console.log(this.state[item.field]);
          if (item.selectdata.type === "hidden") {
            return (
              <input
                key={i}
                type="hidden"
                name={item.field}
                value={this.state[item.field]}
              />
            );
          } else if (item.selectdata.type === "gps") {
            return (
              <input
                key={i}
                type="hidden"
                className="gps_coord"
                name={item.field}
                value={this.state[item.field]}
                onChange={this.handleChange}
              />
            );
          } else if (item.selectdata.type === "file") {
            input = (
              <div>
                <label>{item.name}</label>
                <input
                  type="file"
                  key={i}
                  accept="*"
                  id={item.field}
                  className="inputElement multi"
                  name={item.field}
                  onChange={this.handleChange}
                ></input>
              </div>
            );
          } else if (item.selectdata.type === "select") {
            let options = "";
            if (item.selectdata.values) {
              options = item.selectdata.values.map((item1, i1) => {
                return (
                  <option key={i1} value={item1}>
                    {item1}
                  </option>
                );
              });
            }
            input = (
              <div>
                <label>{item.name}</label>
                <select
                  key={i}
                  name={item.field}
                  className={`form-control chosen-select field_${item.field}`}
                  data-placeholder={item.name}
                  onChange={this.handleChange}
                  value={this.state[item.field]}
                >
                  <option value=""></option>
                  {options}
                </select>
              </div>
            );
          } else {
            input = (
              <div className="mui-textfield mui-textfield--float-label">
                <input
                  type="text"
                  key={i}
                  name={item.field}
                  className="form-control"
                  onChange={this.handleChange}
                  placeholder={item.name}
                  value={this.state[item.field]}
                />
                <label>{item.name}</label>
              </div>
            );
          }
          return (
            <div className="row" key={i}>
              <div className="col-md-12">
                <div className="form-group " style={{ marginBottom: "0px" }}>
                  <div className="col-sm-12">
                    <div
                      style={
                        item.selectdata.conditional &&
                          this.state[item.selectdata.conditional.field] &&
                          this.state[item.selectdata.conditional.field] ===
                          item.selectdata.conditional.value
                          ? { display: "block" }
                          : item.selectdata.conditional
                            ? { display: "none" }
                            : { display: "block" }
                      }
                    >
                      {input}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return "";
        }
      }, this);
    const renderHTML = (rawHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });
    //  console.log("ggggg");
    return (
      <div>
        <div>{renderHTML(this.state.error)}</div>
        {fieldlist}
        {this.Inventory()}
        <div
          className="row form-group"
          style={{ background: "white", paddingTop: "30px" }}
        >
          <div className="col-sm-4 col-sm-offset-2">
            <button
              type="submit"
              onClick={this.handleSubmit}
              className="btn btn-sm btn-primary m-t-n-xs "
            >
              <strong>Save</strong>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportForm;
