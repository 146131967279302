import { Link } from "react-router-dom";
//import ReportForm from "./ReportForm";
import React from "react";
import Job_menu from "./Job_menu";

class File extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  render() {
    let file = this.props.file;

    const renderHTML = (rawHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });
    return (
      <>
        <div
          className="dz-preview dz-complete dz-image-preview"
          style={{ margin: 0 }}
        >
          <div className="dz-details" style={{ position: "relative" }}>
            <a
              className="dz-size btn btn-default"
              style={{ display: "block", padding: 0 }}
              target="_blank"
              data-dz-size=""
              href={file.path}
            >
              <img
                style={{ height: "75px" }}
                data-dz-thumbnail=""
                alt={file.name}
                src={file.thumbnail}
              />
              <div className="dz-filename">
                <span data-dz-name="">{file.name}</span>
              </div>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default File;
