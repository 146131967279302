import React from "react";
import { useState, useEffect } from "react";
import { db } from "../models/model";
const Clock = ({ clock }) => {
  var d = new Date(clock * 1000);
  const current_date = new Date();
  const diffTime = Math.abs(current_date - d);

  let initialHours = Math.floor(diffTime / (1000 * 60 * 60));
  let initialMinute = Math.floor(diffTime / (1000 * 60)) - initialHours * 60;

  let [minutes, setMinutes] = useState(initialMinute);
  let [hours, setHours] = useState(initialHours);
  const [type, setType] = useState(1);
  const [clocktype, setClockType] = useState(1); //1 clock out, 2 - clock in
  const handleTypeChange = (e) => {
    console.log(e.target.value);
    setType(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("clock", 1);
    if (clocktype === 1) formData.append("type_id", type);
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: { lastUpdated: Date().toLocaleString() },
    };
    let token = localStorage.getItem("login");
    let response;
    try {
      response = await fetch(
        "https://starforcecrm.com/clock/clockwidget?token=" + token,
        requestOptions
      );
    } catch (e) {
      db.setrow("user", undefined, undefined).then((user) => {
        if (clocktype === 1) {
          user.clock = false;
        } else {
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          user.clock = parseInt(timestamp / 1000);
        }
        db.setrow("user", user);
        console.log("errr");
        console.log(user);
      });
      window.location.reload();
      return null;
    }
    try {
      const body = await response.text();
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setMinutes(initialMinute);
    setHours(initialHours);
    let myInterval = setInterval(() => {
      if (minutes > 0) {
        setMinutes(minutes + 1);
      }
      if (minutes === 0) {
        if (hours === 0) {
          clearInterval(myInterval);
        } else {
          setHours(minutes + 1);
          setMinutes(59);
        }
      }
    }, 60000);
    return () => {
      clearInterval(myInterval);
    };
  });
  if (clock) {
    return (
      <li className="dropdown clock_in">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          href="#"
          style={{ padding: 0, minHeight: "10px" }}
          aria-expanded="true"
        >
          <button className="btn btn-danger" type="button">
            <i className="fa fa-clock-o"></i>
            <span id="timer">
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}
            </span>
            <i className="fa fa-angle-down"></i>
          </button>
        </a>
        <ul className="dropdown-menu dropdown-clock">
          <li>
            <div className="dropdown-messages-box">
              <div
                className="media-body"
                style={{ textAlign: "center", padding: "20px" }}
              >
                <form onSubmit={onSubmit}>
                  <select
                    name="type_id"
                    onChange={(e) => handleTypeChange(e)}
                    className="form-control"
                    style={{ width: "auto", margin: "10px auto" }}
                  >
                    <option value="1">End Of Day</option>
                    <option value="2">Lunch</option>
                    <option value="3">Break</option>
                    <option value="4">Other</option>
                    <option value="5">Missed Clock-Out</option>
                    <option value="21">Shop Work</option>
                  </select>
                  <button
                    onClick={(e) => setClockType(1)}
                    className="btn btn-danger div_clock"
                    extra=".clock_in"
                    type="submit"
                  >
                    <i className="fa fa-clock-o"></i> Clock Out
                  </button>
                </form>
              </div>
            </div>
          </li>
          <li className="divider"></li>
        </ul>
      </li>
    );
  } else {
    return (
      <li className="dropdown clock_in" style={{ marginLeft: "5px" }}>
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          href="#"
          style={{
            padding: 0,
            minHeight: "10px",
          }}
        >
          <form onSubmit={onSubmit}>
            <button
              onClick={(e) => setClockType(2)}
              className="btn btn-primary div_clock"
              extra=".clock_in"
              type="submit"
            >
              <i className="fa fa-clock-o"></i>Clock In
            </button>
          </form>
        </a>
      </li>
    );
  }
};

export default Clock;
