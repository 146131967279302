import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Dexie from "dexie";
import { useState } from "react";
import Menu from "./components/Menu";
import Ajax from "./components/Ajax";
//import Sync from "./components/Sync";
//import Footer from "./components/Footer";
import About from "./components/About";
import JobsController from "./components/JobsController";
import JobController from "./components/JobController";
import Login from "./components/Login";
const App = () => {
  //const token = "test";
  let props = useParams();
  const token_temp = localStorage.getItem("login");
  const [token, setToken] = useState(token_temp);
  if (!token) {
    return <Login setToken={setToken} />;
  }
  /*
     <Sync key="sync" />*/
  return (
    <Router>
      <div >
        <Menu token={token} />
        <div id="page-wrapper">
          <Routes>
            <Route
              path="/job/:id"
              element={
                <JobController token={token} report={false} key="noreport" />
              }
            />
            <Route
              path="/report/:id"
              element={
                <JobController token={token} report={true} key="report" />
              }
            />
            <Route
              path="/notes/:id"
              element={<JobController token={token} notes={true} key="notes" />}
            />
            <Route path="/ajax" element={<Ajax {...props} />} />

            <Route
              path="/about"
              element={<About token={token} key="about" />}
            />
            <Route
              path="/"
              exact
              element={<JobsController token={token} key="JobsController" />}
            />
            <Route
              path="/"
              element={<JobsController token={token} key="JobsController" />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
};
//  <Footer />
export default App;
