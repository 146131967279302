import { Link } from "react-router-dom";
//import ReportForm from "./ReportForm";
import React from "react";
import { db } from "../models/model";
import moment from "moment";

class Note_add extends React.Component {
  constructor(props) {
    super(props);
    this.state = { description: "", files: "", files_name: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    console.log(event.target.value);
  }
  exist(notes, i) {
    i++;
    console.log("notes");
    console.log(i);
    console.log(notes);
    if (typeof notes != "undefined" && typeof notes[i] != "undefined") {
      i = this.exist(notes, i);
    }
    console.log("test " + i);
    return i;
  }
  async sync(url, requestOptions) {
    let response;
    try {
      response = await fetch(url, requestOptions);
    } catch (e) {
      var postdata = JSON.parse(requestOptions.body);
      var notes = this.props.job.notes;
      var note = {};
      let i = 999999;
      i = this.exist(notes, i);
      note["id"] = i;
      Object.keys(postdata).map(function (key) {
        note[key] = postdata[key];
      });
      notes[i] = note;
      this.props.job.notes = notes;
      db.setdata("jobs", [this.props.job], this.props.job.id);
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.text();
      return body;
    } catch (e) {
      console.log(e);
    }
  }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  handleChange(e) {
    if (e.target.files != undefined) {
      var img = "";
      var file_name = "";
      var files = e.target.files;
      const filePromises = Object.entries(files).map((item) => {
        return new Promise((resolve, reject) => {
          const [index, file] = item;
          const reader = new FileReader();
          reader.readAsBinaryString(file);

          reader.onload = function (event) {
            // handle reader success
            img = btoa(event.target.result);
            console.log(e.target.value);
            console.log(files[0].name);
            console.log(e.target.name);
            if (
              typeof e.target.files[0] != "undefined" &&
              typeof e.target.files[0].name != "undefined"
            ) {
              console.log("ajunge");
              file_name = e.target.files[0].name;
            }
            resolve();
          };

          reader.onerror = function () {
            console.log("couldn't read the file");
            reject();
          };
        });
      });
      Promise.all(filePromises)
        .then(() => {
          this.setState({
            ...this.state,
            [e.target.name]: img,
            ["files_name"]: file_name,
          });
        })
        .catch((error) => {
          console.log(error);
          console.log("something wrong happened");
        });
    } else {
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    var note = this.state;
    note["date_time"] = moment().format("DD-MM-YYYY hh:mm:ss");
    note["date"] = moment().format("hh:mm MM/DD/YYYY");
    note["date_text"] = "now";
    var myObject = JSON.parse(this.props.token);
    note["created_by"] = myObject.fullname;
    note["user_id"] = myObject.id;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(note),
      headers: { lastUpdated: Date().toLocaleString() },
    };
    this.sync(
      `https://starforcecrm.com/api/app/jobs/add_note/?id=${this.props.job.job_id}&token=${this.props.token}`,
      requestOptions
    );

    //here I have to send data and update in report if no internet then add in queue
    console.log(this.state);
    window.location.reload();
  }
  render() {
    return (
      <>
        <form
          autocomplete="off"
          className="add_note_simple simple_note MultiFile-intercepted"
          role="form"
          enctype="multipart/form-data"
        >
          <fieldset>
            <div style={{ marginBottom: "10px" }}>
              <textarea
                className="form-control"
                name="description"
                placeholder="Note"
                onChange={this.handleChange}
              ></textarea>
            </div>
          </fieldset>
          <div>
            <div className="text-left" style={{ display: "inline-block" }}>
              <input type="file" name="files" onChange={this.handleChange} />
            </div>
            <div
              className="text-right"
              style={{ display: "inline-block", float: "right" }}
            >
              <a
                href="#"
                className="btn btn-sm btn-primary m-t-n-xs loading-btn"
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </a>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default Note_add;
