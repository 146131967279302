import Job from "./Job";

const Jobs = ({ jobs }) => {
  let i = 0;
  let k = <></>;
  return (
    <>
      {jobs.map((job, index) => (
        (job['start_time'] != new Date().setHours(0, 0, 0, 0) && i == 0) ? (
          k = <hr style={{ border: "1px solid #000" }} />,
          i++
        ) : (k = <></>),
        < Job key={index} job={job} extra={k} />
      ))}
    </>
  );
};

export default Jobs;
