import { Link } from "react-router-dom";
//import ReportForm from "./ReportForm";
import React from "react";
import Job_menu from "./Job_menu";

class Note extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  render() {
    let note = this.props.note;

    const renderHTML = (rawHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });
    return (
      <>
        <div
          className="feed-element"
          id="notes396767"
          style={{
            paddingLeft: "5px",
            position: "relative",
            minHeight: "41px",
          }}
        >
          <div className="row ellipsis">
            <div className="col-md-3">
              <div className="pull-left">
                <a style={{ paddingRight: "10px" }}>
                  <i
                    className="fa fa-comment-o"
                    style={{ fontSize: "2em", color: "green" }}
                  ></i>
                </a>
              </div>

              <div className="maxoneline">
                <span>
                  <i className="fa fa-copyright"></i>
                  {note.created_by}
                </span>
              </div>
            </div>

            <div className="col-md-9">
              <div className="description newdescr margin_right">
                {renderHTML(note.description)}
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              textAlign: "right",
              top: "5px",
              paddingLeft: "10px",
            }}
            className="date_block"
          >
            <strong className="text-navy"> {note.date_text}</strong>
            <small className="maxoneline text-muted hidden-xs hidden-sm">
              {note.date}
            </small>
          </div>
        </div>
      </>
    );
  }
}

export default Note;
