import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Job = ({ job, extra }) => {
  return (
    <div style={{ marginBottom: "5px" }}>
      {extra}
      <Link to={`/job/${job.id}`}>
        <div
          style={{
            marginBottom: "5px",
            display: "table",
            border: "1px solid #ccc",
            width: "100%",
          }}
        >
          <div
            className="col-xs-4"
            style={{
              float: "none",
              display: "table-cell",
              verticalAlign: "top",
              padding: "15px 10px",
            }}
          >
            <span>
              {job.clock_time_start} <br /> {job.clock_time_end}{" "}
            </span>
            <div className="maxonline">
              <span
                className="btn btn-xs"
                style={{
                  margin: "0 5px",
                  lineHeight: 1,
                  backgroundColor: job.status.color,
                }}
              >
                <i className="{job.status.icon}"></i> {job.status.name}
              </span>
            </div>
          </div>
          <div
            className="col-xs-8"
            style={{
              float: "none",
              display: "table-cell",
              background: "#eee",
              borderLeft: "1px solid #ccc",
              verticalAlign: "top",
              padding: "15px 10px",
            }}
          >
            <div className="maxonline">{job.job_type.name}</div>
            <div
              className="maxonline"
              style={{ fontSize: "1.5rem", lineHeight: "2em" }}
            >
              <span style={{ fontWeight: 700 }}>{job.parent}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Job;
