import Clock from "./Clock";
import { useState, useEffect } from "react";
import { db } from "../models/model";
const Menu = ({ token }) => {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState(false);
  let clockwidget = <Clock clock={user.clock} />;
  const fetchUser = async () => {
    var request = `https://starforcecrm.com/api/app/user/?token=${token}`;
    let response;
    try {
      response = await fetch(request);
    } catch (e) {
      return null;
    }
    try {
      if (response.status === 401) {
        db.destroy();
      }
      const body = await response.json();
      return body;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const GetUser = async () => {
      var data = await fetchUser();
      data = await db.setrow("user", data);
      setUser(data);
    };
    GetUser();

    return () => db.close();
  }, [db]);

  let searchblock = "";
  if (search) {
    searchblock = (
      <form
        role="search"
        className="navbar-form-custom"
        style={{
          marginRight: "10px",
          width: "350px",
          padding: "5px",
          marginTop: "10px",
          background: "#f3f3f4",
          borderRadius: "10px",
          display: "block",
          borderBottom: "1px solid #ccc",
        }}
      >
        <div className="input-group">
          <span
            className="input-group-addon"
            style={{
              zIndex: 1,
              position: "relative",
              border: 0,
              paddingRight: 0,
              background: "none",
            }}
          >
            <i className="fa fa-search"></i>
          </span>
          <input
            type="text"
            placeholder="Search for something..."
            className="form-control ui-autocomplete-input"
            style={{ height: "34px" }}
            name="top-search"
            id="top-search"
          />
          <a
            className="input-group-addon collapse-link btn last_visited hover_btn hidden-sm hidden-xs"
            data-toggle="collapse"
            href="#collapseExample22"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample22"
            title="Show last visited pages"
          >
            <i className="fa fa-history" style={{ fontSize: "20px" }}></i>
          </a>
        </div>
      </form>
    );
  }

  return (
    <div className={isActive ? "fixed-sidebar no-skin-config full-height-layout body-small gray-bg" : "fixed-sidebar no-skin-config full-height-layout body-small mini-navbar"}>
      <nav
        className="navbar-default navbar-static-side"
        role="navigation"
        style={{ backgroundColor: "#2f4050" }}
      >
        <div className="sidebar-collapse">
          <a className="close-canvas-menu" onClick={ToggleClass}>
            <i className="fa fa-times"></i>
          </a>
          <ul className="nav metismenu" id="side-menu">
            <li className="nav-header">
              <div className="dropdown profile-element">
                <span>
                  <img
                    alt="image"
                    src="https://starforcecrm.com/logo-starconnectiontransp.png"
                    style={{ maxWidth: "130px" }}
                  />
                </span>
                <a data-toggle="dropdown" className="dropdown-toggle" href="#">
                  <span className="block m-t-xs">
                    <strong className="font-bold">
                      {user.first_name} {user.last_name}
                    </strong>
                  </span>
                </a>
                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                  <li>
                    <a href={`https://starforcecrm.com/users/edit/${user.id}`}>
                      Profile
                    </a>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <a href="https://starforcecrm.com/logout">Logout</a>
                  </li>
                </ul>
              </div>
              <div className="logo-element">STAR</div>
            </li>
            <li>
              <a href="/">
                <i className="fa fa-dashboard"></i>
                <span className="nav-label">Dashboard</span>
              </a>
            </li>{" "}
            <li>
              <a href="https://starforcecrm.com/activity2/" title="">
                <i className="fa fa-bar-chart-o"></i>
                <span className="nav-label">Activity</span>
              </a>
            </li>{" "}
            <li>
              <a
                href="https://starforcecrm.com/timeline1/"
                target="_blank"
                title=""
              >
                <i className="fa fa-calendar-o"></i>
                <span className="nav-label">Timeline</span>
              </a>
            </li>{" "}
            <li>
              <a
                href="https://starforcecrm.com/inventory/"
                target="_blank"
                title=""
              >
                <i className="fa fa-cubes"></i>
                <span className="nav-label">Inventory</span>
              </a>
            </li>{" "}
            <li>
              <a
                href="https://starforcecrm.com/smartbox1/"
                target="_blank"
                title=""
              >
                <i className="fa fa-tasks"></i>
                <span className="nav-label">Smartbox Data</span>
              </a>
            </li>{" "}
            <li>
              <a
                href="https://remote.starconnection.net/"
                target="_blank"
                title=""
              >
                <i className="fa fa-tasks"></i>
                <span className="nav-label">Remotely</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div id="page-menuwrapper">
        <div className="border-bottom" style={{ marginLeft: "-25px" }}>
          <nav
            className="navbar navbar-static-top menu_top"
            role="navigation"
            style={{ background: "#fff", paddingLeft: "0px" }}
          >
            <div className="navbar-header">

              <a
                className="navbar-minimalize minimalize-styl-2 btn btn-default"
                onClick={ToggleClass}
                style={{ margin: "5px 10px 5px 5px" }}
                href="#"
              >
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  style={{
                    fontSize: "24px",
                    paddingBottom: "5px",
                    paddingTop: "5px",
                    lineHeight: "20px",
                    fill: "#666",
                    verticalAlign: "bottom",
                    height: "40px",
                  }}
                >
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
              </a>

              <a style={{ float: "left" }} href="/">
                <img
                  src="https://starforcecrm.com/logo-starconnectiontransp.png"
                  className="minimal_logo"
                  style={{
                    margin: "5px 10px 5px 5px",
                    padding: "4px 5px",
                    maxHeight: "50px",
                  }}
                />
              </a>
              {search ? (
                <a
                  href="#"
                  className="navbar-form-btn btn btn-success btn-outline"
                  onClick={() => {
                    setSearch(!search);
                  }}
                >
                  <i className="fa fa-search"></i>
                </a>
              ) : (
                ""
              )}
              {searchblock}
              <div
                style={{ paddingTop: "15px", display: "inline-block" }}
                className=" hidden-xs hidden-sm"
              >
                <a
                  className="btn btn-outline btn-primary plus_link dim collapsed"
                  title="Add"
                  style={{
                    marginTop: "2px",
                    marginRight: "15px",
                    display: "inline-block",
                  }}
                  data-toggle="collapse"
                  href="#collapseExampletop"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExampletop"
                >
                  <i className="fa fa-plus"></i>
                </a>
                <div
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <div
                    className="collapse"
                    id="collapseExampletop"
                    aria-expanded="false"
                  >
                    <div style={{ display: "inline-block" }}>
                      <a
                        url="/organization/ajaxadd"
                        data-toggle="modal"
                        className="add_organization btn btn-success special_size btn-outline"
                        type="button"
                        title="Add Organization"
                      >
                        <i className="fa fa-building"></i>
                      </a>
                      <a
                        url="/contact/ajaxadd"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title="Add Contact"
                      >
                        <i className="fa fa-address-book"></i>
                      </a>
                      <button
                        url="/ajax/page/add_call/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="fa fa-phone-square"></i>
                      </button>

                      <button
                        url="/ajax/page/add_deal/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="fa fa-dollar"></i>
                      </button>
                      <button
                        url="/ajax/page/add_action/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="icon-action"></i>
                      </button>
                      <button
                        url="/ajax/page/add_task/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="icon-task"></i>
                      </button>
                      <button
                        url="/ajax/page/add_note/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="fa fa-comment-o"></i>
                      </button>
                      <button
                        url="/ajax/page/add_job/?id=0"
                        data-toggle="modal"
                        className="btn btn-success special_size btn-outline"
                        type="button"
                        title=""
                      >
                        <i className="icon-tools-cross-settings-symbol-for-interface"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <ul
                className="dropdown-search collapse"
                id="collapseExample22"
                style={{
                  position: "absolute",
                  marginLeft: "-30px",
                  marginTop: "1px",
                  zIndex: 999,
                  height: 0,
                }}
                aria-expanded="true"
              >
                <li
                  style={{
                    background: "white",
                    padding: "10px",
                    listStyle: "none",
                    maxWidth: "50%",
                    minWidth: "300px",
                    border: "1px solid rgb(204, 204, 204)",
                    position: "relative",
                  }}
                  className="last_visited_block"
                >
                  <h3 style={{ textAlign: "center", padding: "20px" }}>
                    <i className="fa fa-spinner fa-spin"></i> Loading last
                    visited pages
                  </h3>
                </li>
              </ul>
            </div>

            <ul className="nav navbar-top-links navbar-right">
              <li>
                <span
                  className="dropdown-toggle"
                  style={{
                    padding: 0,
                    minHeight: "10px",
                  }}
                >
                  <a className="btn btn-primary btn-outline btn-lg" href="/" style={{ fontSize: "18px" }}>
                    <i className="icon-tools-cross-settings-symbol-for-interface"></i>
                    <span className="hidden-sm hidden-xs"> Jobs</span>
                  </a>
                </span>
              </li>
              <li className="dropdown">
                <a
                  className="dropdown-toggle count-info btn btn-lg btn-outline"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                  style={{
                    color: "#2f3f50",
                    margin: "0 5px",
                    marginTop: "5px",
                    minHeight: 0,
                    fontSize: "18px",
                    padding: "10px 16px"
                  }}
                >
                  <img
                    alt="image"
                    src={
                      user.img
                        ? `https://starforcecrm.com/public/users/${user.img}`
                        : `https://starforcecrm.com/logo-starconnectiontransp.png`
                    }
                    style={{
                      maxHeight: "40px",
                      border: "1px solid #dadce0",
                      borderRadius: "10px",
                    }}
                  />
                </a>
                <ul className="dropdown-menu dropdown-alerts">
                  <li>
                    <a href={`https://starforcecrm.com/users/edit/${user.id}`}>
                      <div>
                        <i className="fa fa-user fa-fw"></i> Profile
                        <span className="pull-right text-muted small">
                          {user.first_name} {user.last_name}
                        </span>
                      </div>
                    </a>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <div className="text-center link-block">
                      <a
                        href="/?logout"
                        onClick={() => {
                          db.destroy();
                        }}
                      >
                        <i className="fa fa-sign-out"></i>
                        <strong>Logout</strong>
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
              {clockwidget}


            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Menu;
