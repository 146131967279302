import Job_menu from "./Job_menu";
import Job_buttons from "./Job_buttons";
const Job_single = ({ job, token }) => {
  let single = job[0];
  var style = { fontSize: "1.2em" };
  return (
    <>
      <div>
        <Job_buttons job={{ single }} token={{ token }} />
        <Job_menu job={{ single }} token={{ token }} />
        <div
          className="ibox-content add_job"
          id="add_job"
          style={{ padding: "5px" }}
        >
          <div className="job_details">
            <div className="row">
              <div className="col-sm-6">
                <label>Customer</label>
                <div style={style}>
                  <a
                    href={`https://starforcecrm.com/${single.parent_tabel}/detail/${single.parent_id}`}
                    target="_blank"
                  >
                    <span style={{ fontWeight: "700" }}>{single.parent}</span>
                  </a>
                </div>
              </div>{" "}
              <div className="col-sm-6">
                <label>Phone</label>
                <div style={style}>
                  <a href={`tel:${single.job.phone}`} target="_blank">
                    <strong>{single.job.phone}</strong>
                  </a>
                </div>
              </div>
            </div>{" "}
            <div className="row">
              {" "}
              <div className="col-sm-6">
                <label>Location</label>
                <div style={style}>
                  <a
                    href={`https://www.google.com/maps/search/${single.job.street}`}
                    target="_blank"
                  >
                    <strong>{single.job.street}</strong>
                  </a>
                </div>
              </div>
              <div className="col-sm-6">
                <label>Job Type</label>

                <div style={style}>
                  {single.job_type.name} ({single.job_type.job_duration})
                </div>
              </div>
            </div>
            <div className="row">
              {" "}
              <div className="col-sm-6">
                <label>Contact</label>
                <div style={style}>
                  <a
                    href={`https://starforcecrm.com/${single.parent_tabel}/detail/${single.parent_id}`}
                    target="_blank"
                  >
                    <strong>{single.contact}</strong>
                  </a>
                </div>
              </div>
              <div className="col-sm-6">
                <label>Contact Phone</label>
                <div style={style}>
                  <a href={`tel:${single.job.org_phone}`} target="_blank">
                    <strong>{single.org_phone}</strong>
                  </a>
                </div>
              </div>
            </div>{" "}
            <div className="row">
              <div className="col-md-4">
                <label>Sales Rep.</label>
                <div style={style}>{single.job.sales_rep}</div>
              </div>{" "}
              <div className="col-md-4">
                <label>Account #</label>
                <div style={style}>{single.job.account}</div>
              </div>{" "}
              <div className="col-md-4">
                <label>Agreement #</label>
                <div style={style}>{single.job.agrement}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Est. Amount</label>
                <div style={style}>{single.job.est_amount}</div>
              </div>
              <div className="col-md-8">{single.job.est_descr}</div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Actual Amount</label>
                <div style={style}>{single.job.act_amount}</div>
              </div>
              <div className="col-md-8">{single.job.act_description}</div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Money Collected</label>
                <div style={style}>{single.job.did_colect}</div>
              </div>
              <div className="col-md-8">{single.job.colect_description}</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label>Job Summary</label>
                <div style={style}>{single.job.job_description}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Start Job Time</label>
                <div style={style}>{single.start}</div>
              </div>{" "}
              <div className="col-md-4">
                <label>End Job Time</label>
                <div style={style}>{single.end}</div>
              </div>{" "}
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Technician</label>
                <div style={style}>{single.techs}</div>
              </div>
              <div className="col-md-4">
                <label>Status</label>
                <div style={style}>{single.status.name} </div>
              </div>{" "}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Job_single;
