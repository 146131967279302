import { Link } from "react-router-dom";
import { useState } from "react";
const Job_menu = ({ job, token }) => {
  let single = job.single;

  const fetchData = async (event) => {
    return false;
  };
  let preInstall = "";
  if (typeof single.preinstall != "undefined") {
    preInstall = <li role="presentation">
      <a
        href="#timesheet"
        className="send time_sheet"
        extra=".job_details"
        value={`/deal_preinstall/ajax/edit/${single.preinstall}`}
      >
        PreInstall
      </a>
    </li>
  }
  let axiom = "";
  if (typeof single.deal_id != "undefined") {
    axiom = <li role="presentation">
      <a
        href="#timesheet"
        className="send time_sheet"
        extra=".job_details"
        value={`/axiom/info/${single.deal_id}`}
      >
        Axiom
      </a>
    </li>
  }
  // {`/timeline1/show_notes?id=${single.job_id}&customtype=jobs`}
  /* <Link to={`/report/${single.id}`}>Report </Link> */
  return (
    <ul className="nav nav-tabs special_jobmenu" role="tablist">
      <li role="presentation">
        <a href={`/job/${single.id}`}>Job</a>
      </li>
      <li role="presentation">
        <a href={`/report/${single.id}`}>Report</a>
      </li>
      <li role="presentation">
        <a href={`/notes/${single.id}`}>Notes</a>
      </li>
      <li role="presentation">
        <a
          href="#"
          className="send"
          style={{ color: "#999" }}
          extra=".job_details"
          value={`/inv/view_all/jobs/${single.job_id}`}
        >
          Inventory
        </a>
      </li>
      <li role="presentation">
        <a
          href="#timesheet"
          className="send time_sheet"
          extra=".job_details"
          value={`/clock/special_tech/${single.job_id}&site_visit=${single.id}`}
        >
          Time Sheet
        </a>
      </li>
      {preInstall}
      {axiom}

      <li role="presentation">
        <a
          href="#job"
          className="send"
          extra=".job_details"
          value={`/activity2/get_data_detail/0?limit=20&condition=updates:(on)%20job_id:(${single.job_id})&hide_activity=true&extra=.job_details&token=${token}`}
        >
          Log
        </a>
      </li>
    </ul>
  );
};

export default Job_menu;
