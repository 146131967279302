import { Link } from "react-router-dom";
//import ReportForm from "./ReportForm";
import React from "react";
import Job_menu from "./Job_menu";
import Note from "./Note";
import File from "./File";
import Note_add from "./Note_add";
class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    console.log(event.target.value);
  }

  handleSubmit(event) {
    alert("A name was submittedffff: ");
    //here I have to send data and update in report if no internet then add in queue
    console.log(this.state);
    event.preventDefault();
  }

  render() {
    let single = this.props.job[0];
    console.log(single.notes);
    let token = this.props.token;
    /*
     {single.notes.map((note, index) => (
                <Note key={index} note={note} />
              ))}
    */
    return (
      <>
        <div>
          <Job_menu job={{ single }} token={{ token }} />
          <div
            className="ibox-content add_job"
            id="add_job"
            style={{ padding: "5px" }}
          >
            <div className="job_details">
              <form className="dropzone" style={{ marginBottom: "10px" }}>
                {single.files.map((file, index) => (
                  <File key={index} file={file} />
                ))}
              </form>
              <Note_add job={single} token={token} />
              {Object.values(single.notes)
                .reverse()
                .map((note, index) => (
                  <Note key={index} note={note} />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Notes;
