import Jobs from "./Jobs";
import { useState, useEffect } from "react";
import { db } from "../models/model";

const JobsController = ({ token }) => {
  const [jobs, setJobs] = useState([]);

  const fetchJobs = async () => {
    var request = `https://starforcecrm.com/api/app/jobs/?token=${token}`;
    let response;
    try {
      response = await fetch(request);
    } catch (e) {
      return null;
    }
    try {
      if (response.status == 401) {
        db.destroy();
      }
      const body = await response.json();
      return body;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const GetJobs = async () => {
      var data = await fetchJobs();
      data = await db.setdata("jobs", data);
      setJobs(data);
    };
    GetJobs();

    return () => db.close();
  }, [db]);

  return (
    <>
      {jobs.length > 0 ? (
        <Jobs jobs={jobs} />
      ) : (
        <h2 className="text-center">No Jobs To Show</h2>
      )}
    </>
  );
};

export default JobsController;
