import React, { useState } from "react";

import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";

export default function Login(setToken) {
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    var data = {};
    data["user_password"] = password;
    data["user_name"] = email;
    try {
      const res = await fetch("https://starforcecrm.com/api/app/login", {
        method: "POST",
        body: JSON.stringify(data),
      }).catch((e) => {
        console.log(e);
        setError(<div style={{ color: "red" }}>An error has occured</div>);
        throw new Error(e.message);
      });
      if (!res.ok) {
        setError(
          <div style={{ color: "red" }}>
            An error has occured: ${res.status}
          </div>
        );
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
      }

      const logindata = await res.json();
      if (logindata.token) {
        localStorage.setItem("login", JSON.stringify(logindata));
        window.location.reload(false);
      } else if (logindata.errors) {
        console.log(logindata.errors);
        logindata.errors.map((error) => {
          setError(<div style={{ color: "red" }}>{error}</div>);
        });
      }
      console.log(logindata);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="Login container">
      {error}
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>

          <Form.Control
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>

          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Button size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
}
