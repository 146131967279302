import { Link } from "react-router-dom";
import ReportForm from "./ReportForm";
import React from "react";
import Job_menu from "./Job_menu";

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    console.log(event.target.value);
  }

  handleSubmit(event) {
    console.log("test");
    console.log(event);
    // alert("A name was submittedffff: ");
    //here I have to send data and update in report if no internet then add in queue
    console.log(this.state);
    event.preventDefault();
  }

  render() {
    let single = this.props.job[0];
    console.log(single);
    let token = this.props.token;
    return (
      <>
        <div>
          <Job_menu job={{ single }} token={{ token }} />
          <div
            className="ibox-content add_job"
            id="add_job"
            style={{ padding: "5px" }}
          >
            <div className="job_details">
              <form
                method="post"
                encType="multipart/form-data"
                className=" MultiFile-intercepted"
              >
                <div className="tab-content">
                  <div id="tab-1" className="tab-pane active">
                    <div className="panel-body">
                      <fieldset className="form-horizontal">
                        <ReportForm job={single} token={token} />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </form>
            </div>{" "}
          </div>{" "}
        </div>
      </>
    );
  }
}
/*
const Job_single = ({ job }) => {

  handleChange(event) {
    this.setState({value: event.target.value});
  }


  let single = job[0];
  
}; */

export default Report;
